import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "save",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#save",
        "aria-label": "save permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Save`}</h2>
    <p>{`When your note is complete, you want to save it.`}</p>
    <p>{`Saving a note saves it `}<strong parentName="p">{`locally, on your desktop computer`}</strong>{` (meaning you can be offline).`}</p>
    <h2 {...{
      "id": "push",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#push",
        "aria-label": "push permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Push`}</h2>
    <p>{`Push your notes to save your changes to the cloud.`}</p>
    <h2 {...{
      "id": "publish",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#publish",
        "aria-label": "publish permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Publish`}</h2>
    <p>{`Push your notes to save your changes to the cloud.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      